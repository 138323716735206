import Layout from 'components/layout';
import StickyMenu from 'components/sticky-menu';
import { graphql } from 'gatsby';
import React from 'react';

const CareerPage = ({location, data}) => {
  const { html } = data?.markdownRemark;

  return (
    <Layout path={location.pathname}>
      <StickyMenu pageSlug={location.pathname} visible />
      <section className="mt-48">
        <div className="container">
          <div className="row offer">
            <div className="col-span-12">
              <div
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query MyQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(frontmatter: {title: {eq: "Privacy page"}}) {
      html
    }
  }
`;

export default CareerPage;
